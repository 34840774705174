import React from "react";
import { Link, graphql } from "gatsby";
import get from "lodash/get";

import "../fonts/fonts-post.css";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import { formatPostDate, formatReadingTime } from "../utils/helpers";
import { rhythm, scale } from "../utils/typography";
import { systemFont, lang } from '../constants';
import PaginationLink from "../components/PaginationLink";

class BlogPostTemplate extends React.Component {
    render() {
        const post = this.props.data.markdownRemark;
        const siteTitle = get(this.props, "data.site.siteMetadata.title");
        let { previous, next } = this.props.pageContext;
        console.log(previous)

        // Replace original links with translated when available.
        let html = post.html;

        return (
            <Layout location={this.props.location} title={siteTitle}>
                <SEO
                    lang={"zh-hans"}
                    title={post.frontmatter.title}
                    description={post.frontmatter.spoiler}
                    slug={post.fields.slug}
                />
                <main>
                    <article>
                        <header>
                            <h1 style={{ color: "var(--textTitle)", marginTop: rhythm(1) }}>
                                {post.frontmatter.title}
                            </h1>
                            <p
                                style={{
                                    ...scale(-1 / 5),
                                    display: "block",
                                    marginBottom: rhythm(1),
                                    marginTop: rhythm(-4 / 5)
                                }}
                            >
                                {formatPostDate(post.frontmatter.date, lang)}
                                {` • ${formatReadingTime(post.timeToRead)}`}
                            </p>
                        </header>
                        <div dangerouslySetInnerHTML={{ __html: html }} />
                    </article>
                </main>
                <aside>
                    <div
                        style={{
                            margin: "90px 0 40px 0",
                            fontFamily: systemFont
                        }}
                    >
                    </div>
                    <nav>
                        <ul
                            style={{
                                display: "flex",
                                flexWrap: "wrap",
                                justifyContent: "space-between",
                                listStyle: "none",
                                padding: 0,
                                margin: 0,
                            }}
                        >
                            <li>
                                {previous && (
                                    <PaginationLink
                                        to={previous.fields.slug}
                                        direction="prev">
                                        {previous.frontmatter.title}
                                    </PaginationLink>
                                )}
                            </li>
                            <li>
                                {next && (
                                    <PaginationLink
                                        to={next.fields.slug}
                                        direction="next">
                                        {next.frontmatter.title}
                                    </PaginationLink>
                                )}
                            </li>
                        </ul>
                    </nav>
                </aside>
            </Layout>
        );
    }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
    query BlogPostBySlug($slug: String!) {
        site {
            siteMetadata {
                title
                author
            }
        }
        markdownRemark(fields: { slug: { eq: $slug } }) {
            id
            html
            timeToRead
            frontmatter {
                title
                date(formatString: "MMMM DD, YYYY")
                spoiler
                cta
            }
            fields {
                slug
            }
        }
    }
`;
