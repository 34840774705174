export function formatReadingTime(minutes) {
  let cups = Math.round(minutes / 5);
  let bowls = 0;
  if (cups > 5) {
    return `${new Array(Math.round(cups / Math.E))
      .fill("🍱")
      .join("")} 阅读时间：${minutes}分钟`;
  } else {
    return `${new Array(cups || 1).fill("☕️").join("")} 阅读时间：${minutes}分钟`;
  }
}

// `lang` is optional and will default to the current user agent locale
export function formatPostDate(date, lang) {
  if (typeof Date.prototype.toLocaleDateString !== "function") {
    return date;
  }

  date = new Date(date);
  const args = [
    lang,
    { day: "numeric", month: "long", year: "numeric" }
  ].filter(Boolean);
  return date.toLocaleDateString(...args);
}
