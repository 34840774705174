import React from "react";
import { Link } from "gatsby";

const styles = {
    link: {
        display: "flex",
        justifyContent: "center",
        boxShadow: "none",
    },
    span: {
        lineHeight: "24px",
        fontWeight: 700,
    }
}

const icons = {
    next: <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" fill="#467fff"><path d="M10 17l5-5-5-5v10z" /><path d="M0 24V0h24v24H0z" fill="none" /></svg>,
    prev: <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M14 7l-5 5 5 5V7z" fill="#467fff" /><path d="M24 0v24H0V0h24z" fill="none" /></svg>
};

export default function ({ direction, to, children }) {
    return (
        <Link className="pagination-link" to={to} rel={direction} style={styles.link}>
            {direction === "prev" && icons["prev"]}
            <span style={styles.span}> {children} </span>
            {direction === "next" && icons["next"]}
        </Link>
    )
}